import React from "react";
import CountUp from "react-countup";

import parseCommasToThousands from "../../utils/parseCommasToThousands";

interface StakingStatProps {
  value: number;
  sweetPrice: number;
}

export const TotalRevenueShared = (props: StakingStatProps) => {
  let { value, sweetPrice } = props;

  // Add the amount which wasn't set at the migration -> get the real amount shared
  value += 130843.35;

  return (
    <div className="bg-card-background border border-border-primary w-full h-full p-4 rounded-md">
      <div className="text-text text-xl">Total Revenue Shared</div>
      <div className="w-full flex flex-row justify-center items-center mt-12">
        <span className="text-white font-bold text-4xl">
          <CountUp end={value} />
        </span>
        <span className="text-2xl font-bold text-sweet ml-2">$SWEET</span>
      </div>
      <div className="w-full flex flex-row justify-center items-center mt-0.5">
        {sweetPrice ? (
          <span className="text-text text-md">
            $<CountUp end={Number(value) * sweetPrice} />
          </span>
        ) : (
          <div className="h-4 bg-gray-700 w-24 mt-1.5 animate-pulse"></div>
        )}
      </div>
    </div>
  );
};
