const cutDecimalPoints = (num: number) => {
  let stringNum = num.toString();
  let matches = stringNum.match(/^-?\d+(?:\.\d{0,2})?/);
  return matches ? matches[0] : "0";
};

// Parse commas into thousands
const parseCommasToThousands = (value: any, noCommas = false) => {
  if (!noCommas && typeof value === "number") {
    value = value.toFixed(2);
  }

  if (noCommas) {
    value = cutDecimalPoints(value);
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default parseCommasToThousands;
