import React, { useEffect, useState } from "react";
import { useAccount, useContractRead, useSigner } from "wagmi";
import { ethers } from "ethers";
import * as Sentry from "@sentry/nextjs";

import { Withdraw } from "./Withdraw";
import {
  STAKING_ABI,
  STAKING_ADDRESS,
  SWEET_TOKEN_ABI,
  SWEET_TOKEN_ADDRESS,
  formatNumber,
} from "../../utils/web3";
import toast from "react-hot-toast";
import parseCommasToThousands from "../../utils/parseCommasToThousands";

interface Props {
  children?: React.ReactNode;
}

const Content = ({ children }: Props) => {
  return (
    <div className="bg-card-background rounded-md border border-border-primary w-full">
      <div className="p-6 w-full flex flex-col gap-2">{children}</div>
    </div>
  );
};

const StakingAction = ({
  type,
  initialValue,
  max,
}: {
  type: "stake" | "withdraw";
  initialValue?: number;
  max?: number;
}) => {
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const [value, setValue] = useState<number>(initialValue || 0);

  const handleMaxClick = () => {
    if (max) {
      // Remove all decimals after 2nd
      max = Math.floor(max * 100) / 100;

      setValue(max);
    }
  };

  const _handleApproval = async (contract: ethers.Contract) => {
    const currentAllowance = await contract.allowance(address, STAKING_ADDRESS);
    console.log("current allowance: ", currentAllowance.toString());

    // Check if current allowance is enough
    if (!currentAllowance.lt(value * 1e9)) {
      console.log("current allowance is enough");
      return;
    }

    const transaction = await contract.approve(
      STAKING_ADDRESS,
      ethers.utils.parseUnits(value.toString(), 9)
    );
    const receipt = await transaction.wait();

    if (receipt && receipt.blockNumber) {
      if (receipt.confirmations && receipt.confirmations >= 1) {
        toast.success("Successfully Approved!");
      } else {
        toast.error("Failed to Approve!\nTransaction Failed.");
      }
    } else {
      toast.error("Failed to Approve!\nTransaction Failed.");
    }
  };

  const handleStake = () => {
    const sweetTokenContract = new ethers.Contract(
      SWEET_TOKEN_ADDRESS,
      SWEET_TOKEN_ABI,
      signer as ethers.Signer
    );

    _handleApproval(sweetTokenContract).catch((err) => {
      console.log("error approving: ", err);
      toast.error("Failed to Approve!\nTransaction Failed.");
    });

    const stakingContract = new ethers.Contract(
      STAKING_ADDRESS,
      STAKING_ABI,
      signer as ethers.Signer
    );

    const transaction = stakingContract.stake(
      ethers.utils.parseUnits(value.toString(), 9)
    );

    transaction
      .then(() => {
        toast.success("Successfully Staked!");
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
        Sentry.captureException(err);
      });
  };

  const handleWithdraw = () => {
    const stakingContract = new ethers.Contract(
      STAKING_ADDRESS,
      STAKING_ABI,
      signer as ethers.Signer
    );

    const transaction = stakingContract.unstake(
      ethers.utils.parseUnits(value.toString(), 9)
    );

    transaction
      .then(() => {
        toast.success("Successfully Withdrawn!");
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
        Sentry.captureException(err);
      });
  };

  const _handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value < 0) {
      setValue(0);
    } else {
      if (value > max!) {
        setValue(max!);
      } else {
        setValue(value);
      }
    }
  };

  return (
    <>
      <div className="flex items-center w-full bg-background-input px-2 py-1.5 rounded-md border-2 border-border-alt mb-1">
        <input
          className="w-full h-8 rounded-m text-center border-none outline-none bg-transparent text-white focus:ring-0"
          value={value}
          onChange={_handleInputChange}
        />
        <button
          className="ml-2 px-4 py-1 rounded-md text-text bg-[#1f2734] hover:opacity-90"
          onClick={handleMaxClick}
        >
          Max
        </button>
      </div>
      <button
        onClick={() => {
          if (type === "stake") {
            handleStake();
          } else {
            handleWithdraw();
          }
        }}
        className="w-full sm:w-auto text-sm bg-button-background-alt rounded-md text-white p-2 bg-button-border-blue gap-2 items-center flex justify-center hover:opacity-90 mb-10"
      >
        {type === "stake" ? "Stake" : "Withdraw"} Tokens
      </button>
    </>
  );
};

const stakingContractConfig = {
  address: STAKING_ADDRESS,
  abi: STAKING_ABI,
} as const;

const sweetTokenContractConfig = {
  address: SWEET_TOKEN_ADDRESS,
  abi: SWEET_TOKEN_ABI,
} as const;

export const UserPanel = () => {
  const [userTotalStaked, setUserTotalStaked] = useState<number>(0);
  const [userBalance, setUserBalance] = useState<number>(0);
  const [userClaimable, setUserClaimable] = useState<number>(0);

  const { address, status } = useAccount();

  const { data: totalStaked } = useContractRead({
    ...stakingContractConfig,
    functionName: "totalStakedOf",
    args: [address],
    watch: true,
  });

  useEffect(() => {
    if (totalStaked) {
      const stringTotalStaked = totalStaked.toString();
      setUserTotalStaked(formatNumber(Number(stringTotalStaked)));
    }
  }, [totalStaked]);

  const { data: totalBalance } = useContractRead({
    ...sweetTokenContractConfig,
    functionName: "balanceOf",
    args: [address],
    watch: true,
  });

  useEffect(() => {
    if (totalBalance) {
      const stringTotalBalance = totalBalance.toString();
      setUserBalance(formatNumber(Number(stringTotalBalance)));
    }
  }, [totalBalance]);

  const { data: claimable } = useContractRead({
    ...stakingContractConfig,
    functionName: "totalClaimableOf",
    args: [address],
    watch: true,
  });

  useEffect(() => {
    if (claimable) {
      const stringClaimable = claimable.toString();
      setUserClaimable(formatNumber(Number(stringClaimable)));
    }
  }, [claimable]);

  return (
    <div className="relative">
      {status !== "connected" && (
        <div className="absolute z-10 backdrop-filter backdrop-blur-sm bg-opacity-50 bg-black w-full h-full flex flex-col justify-center items-center">
          <p className="text-sm font-semibold text-white">
            CONNECT YOUR WALLET TO STAKE
          </p>
        </div>
      )}
      <Content>
        <div className="text-text mb-10">
          My Staked Balance{" "}
          <span className="text-white">
            {userTotalStaked ? parseCommasToThousands(userTotalStaked) : "-"}
          </span>{" "}
          <span className="text-sweet">SWEET</span>
        </div>
        <StakingAction type="stake" max={userBalance} />
        <StakingAction type="withdraw" max={userTotalStaked} />
        <Withdraw claimableRevenueShare={userClaimable} />
      </Content>
    </div>
  );
};
