import { BigNumber, ethers } from "ethers";
import web3 from "web3";

// Sweet Token Contract
export const SWEET_TOKEN_ADDRESS = "0x2df7d0e4903029717c949cad204075a3d75c8806";
export const SWEET_TOKEN_ABI = require("../../abi/sweet.json");

// Sweet Staking Contract
export const STAKING_ADDRESS = "0xd5892d49BE2537e4c54C4DDE376EDEC4b3802B82";
export const STAKING_ABI = require("../../abi/staking.json");

const web3Handler = new web3(
  new web3.providers.HttpProvider("https://ethereum.twnodes.com")
);

export const formatNumber = (num: number | string): number => {
  if (typeof num === "string") {
    num = parseInt(num);
  }

  return num / 10 ** 9;
};

export const getTotalStaked = async (): Promise<number> => {
  const contract = new web3Handler.eth.Contract(STAKING_ABI, STAKING_ADDRESS);
  const result = await contract.methods.totalStaked().call();
  return formatNumber(result);
};

export const getTotalRevenueShared = async (): Promise<number> => {
  const contract = new web3Handler.eth.Contract(STAKING_ABI, STAKING_ADDRESS);
  const result = await contract.methods.totalDistributed().call();
  return formatNumber(result);
};

const BURN_ADDRESS = "0x0000000000000000000000000000000000000000";
const DEAD_ADDRESS = "0x000000000000000000000000000000000000dead";

export const getTotalBurned = async (): Promise<number> => {
  const contract = new web3Handler.eth.Contract(
    SWEET_TOKEN_ABI,
    SWEET_TOKEN_ADDRESS
  );

  const result = await contract.methods.balanceOf(BURN_ADDRESS).call();
  const result2 = await contract.methods.balanceOf(DEAD_ADDRESS).call();

  return (parseInt(result) + parseInt(result2)) / 1e9;
};
