import React from "react";
import { useRouter } from "next/router";
import classnames from "classnames";

// Assets
import logo from "/public/images/sweet-textlogo.svg";

export const Header = () => {
  const router = useRouter();

  const onImageClick = () => {
    window.location.href = "https://sweet.gg";
  };

  const clickOnStaking = () => {
    router.push("/");
  };

  const clickOnAnalytics = () => {
    router.push("/analytics");
  };

  return (
    <header className="w-full sm:ml-4 sm:mr-4">
      <div className="p-8 sm:p-12 flex justify-center md:justify-normal">
        <img
          src={logo.src}
          alt="Sweet"
          className="h-10 sm:h-14 cursor-pointer hover:opacity-90 transition-opacity duration-300 ease-in-out"
          onClick={onImageClick}
        />
        <div className="border-l-2 border-border-primary pl-4 ml-4 flex items-center">
          <span
            className={classnames(
              "text-lg sm:text-md font-bold tracking-wider cursor-pointer hover:opacity-90 transition-opacity duration-300 ease-in-out",
              {
                "text-white": router.pathname === "/",
                "text-text": router.pathname !== "/",
              }
            )}
            onClick={clickOnStaking}
          >
            STAKING
          </span>
          <span
            className={classnames(
              "text-lg sm:text-md font-bold tracking-wider ml-4 cursor-pointer hover:opacity-90 transition-opacity duration-300 ease-in-out",
              {
                "text-white": router.pathname === "/analytics",
                "text-text": router.pathname !== "/analytics",
              }
            )}
            onClick={clickOnAnalytics}
          >
            ANALYTICS
          </span>
        </div>
      </div>
    </header>
  );
};
