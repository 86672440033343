import { ethers } from "ethers";
import toast from "react-hot-toast";
import { useState } from "react";
import Image from "next/image";
import { useSigner } from "wagmi";
import { STAKING_ABI, STAKING_ADDRESS } from "../../utils/web3";

import ShardsIcon from "/public/icons/staking_shards.svg";

type Status =
  | "CONFIRMING"
  | "CLAIMING"
  | "APPROVING"
  | "NEEDS_APPROVAL"
  | "LOADING"
  | null;

export const Withdraw = ({
  claimableRevenueShare,
}: {
  claimableRevenueShare: number | undefined;
}) => {
  const [status, setStatus] = useState<Status>("LOADING");
  const { data: signer } = useSigner();

  const handleClaim = async () => {
    setStatus("CLAIMING");

    const stakingContract = new ethers.Contract(
      STAKING_ADDRESS,
      STAKING_ABI,
      signer as ethers.Signer
    );

    try {
      const transaction = await stakingContract.claim();
      const receipt = await transaction.wait();

      if (receipt && receipt.blockNumber) {
        if (receipt.confirmations && receipt.confirmations >= 1) {
          toast.success("Successfully Claimed Balance!");
        } else {
          toast.error("Failed to Claim Balance!\nTransaction Failed.");
        }
      } else {
        toast.error("Failed to Claim Balance!\nTransaction Failed.");
      }
      setStatus(null);
    } catch (err) {
      setStatus(null);
      toast.error("User Rejected Action");
    }

    setStatus(null);
  };

  return (
    <>
      <div className="w-full bg-background-alt p-4 rounded-md border-2 border-border-primary mt-9 flex flex-col gap-2 justify-center">
        <div className="flex flex-row gap-2 items-center">
          <Image src={ShardsIcon.src} width={"24px"} height={"24px"} alt={""} />
          <p className="text-white">
            Claim{" "}
            {String(Number(claimableRevenueShare?.toString().substring(0, 5)))}{" "}
            <b className="text-sweet">$SWEET</b> Revenue Share
          </p>
        </div>
        <button
          className="mt-2 w-full flex justify-center gap-2 align-middle items-center px-4 py-2  text-sm font-medium rounded-md text-white box-border bg-button-background-green  border-2 border-button-border-green hover:bg-button-hover-green active:bg-button-active-green active:border-button-background-green"
          onClick={handleClaim}
          disabled={status === "CLAIMING" || status === "CONFIRMING"}
        >
          {status === "CLAIMING" ? "Confirming..." : "Claim Revenue Share"}
        </button>
      </div>
    </>
  );
};
