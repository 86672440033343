import parseCommasToThousands from "../../utils/parseCommasToThousands";

interface StakingStatProps {
  header: string;
  value?: string | number;
  showSweetLabel?: boolean;
  round?: boolean;
  isNumber?: boolean;
  sweetPrice?: number;
  hasSubtitle?: boolean;
}

export const StakingStat = (props: StakingStatProps) => {
  const {
    showSweetLabel,
    value,
    header,
    round = true,
    sweetPrice,
    hasSubtitle = false,
  } = props;

  return (
    <div className="bg-card-background border border-border-primary w-full h-full p-4 rounded-md">
      <span className="text-text text-md">{header}</span>
      <div className="w-full flex flex-row justify-between items-center mt-2">
        {!!value ? (
          <span className="text-white font-bold text-2xl">
            {parseCommasToThousands(
              round ? Math.floor(Number(value)).toString() : value
            )}
          </span>
        ) : (
          <span className="animate-pulse font-bold text-2xl"></span>
        )}
        {showSweetLabel && (
          <span className="text-xs text-blue-default ml-1 font-bold space-x-1">
            /$SWEET
          </span>
        )}
      </div>
      {hasSubtitle && (
        <div className="w-full flex flex-row justify-between items-center mt-0.5 text-text text-sm">
          {sweetPrice ? (
            <span>${parseCommasToThousands(Number(value) * sweetPrice)}</span>
          ) : (
            <div className="h-2.5 bg-gray-700 w-24 mt-1.5 animate-pulse"></div>
          )}
        </div>
      )}
    </div>
  );
};
