import { useEffect, useState } from "react";
import Image from "next/image";
import Layout from "../components/layout";
import { StakingStat } from "../components/staking-page/StakingStat";
import { UserPanel } from "../components/staking-page/UserPanel";
import {
  STAKING_ADDRESS,
  getTotalBurned,
  getTotalRevenueShared,
  getTotalStaked,
} from "../utils/web3";
import { toast } from "react-hot-toast";

import EtherscanIcon from "/public/icons/staking_etherscan.svg";
import { TotalRevenueShared } from "../components/staking-page/TotalRevenueShared";

const Staking = (): JSX.Element => {
  const [globalStakeCount, setGlobalStakeCount] = useState<number>(0);
  const [totalRevenueShared, setTotalRevenueShared] = useState<number>(0);
  const [totalBurned, setTotalBurned] = useState<number>(0);

  // Fetch global stake count
  useEffect(() => {
    const fetchData = async () => {
      const [stakeCount, revenue, burned] = await Promise.all([
        getTotalStaked(),
        getTotalRevenueShared(),
        getTotalBurned(),
      ]);

      setGlobalStakeCount(stakeCount);
      setTotalRevenueShared(revenue);
      setTotalBurned(burned);
    };
    fetchData().catch((e) => {
      toast.error("Failed to Retrieve Global Data\nPlease try again later.");
    });
  }, []);

  const [sweetPrice, setSweetPrice] = useState<number>(0);

  useEffect(() => {
    const socket = new WebSocket("wss://api.sweet.gg/socket");

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message.type === "update_sweet_token_data") {
          const data = JSON.parse(message.data);
          console.log(data);
          setSweetPrice(data.price_usd);
        }
      } catch (e) {
        console.error(e);
      }
    };
  }, []);

  return (
    <Layout>
      <div className="px-6 md:px-8 flex items-center justify-center flex-col w-full max-w-7xl">
        <div className="w-full text-3xl font-extrabold text-white mb-4 text-center">
          STAKE <span className="text-sweet">$SWEET</span>, RECEIVE REWARDS AND
          GAMBLE ON!
        </div>
        <div className="w-full text-md text-text mb-16 text-center">
          <span>
            Earn rewards based on how much $SWEET you stake. The more you stake,
            the more you earn.
          </span>
          <br />
          <span>
            Learn{" "}
            <a
              className="text-sweet cursor-pointer"
              href="https://sweetgg.gitbook.io/sweet-whitepaper/sweet-ecosystem/revenue-share"
              target="_blank"
              rel="noopener noreferrer"
            >
              how it works
            </a>
            .
          </span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-[55%_auto]  gap-8 w-full">
          <div className="w-full h-full">
            <div className="w-full h-[200px] mb-4">
              <TotalRevenueShared
                value={totalRevenueShared}
                sweetPrice={sweetPrice}
              />
            </div>
            <ul className="w-full grid grid-cols-2 mb-4 gap-4">
              <StakingStat
                header={"Tokens Staked"}
                value={String(globalStakeCount)}
                sweetPrice={sweetPrice}
                hasSubtitle={true}
              />
              <StakingStat
                header={"Tokens Staked %"}
                value={String(
                  `${(
                    (globalStakeCount / (100_000_000 - totalBurned)) *
                    100
                  ).toFixed(2)}%`
                )}
                round={false}
              />
              <StakingStat
                header={"Tokens Burned"}
                value={String(totalBurned)}
                sweetPrice={sweetPrice}
                hasSubtitle={true}
              />
              <StakingStat
                header={"Tokens Burned %"}
                value={String(
                  `${((totalBurned / 100_000_000) * 100).toFixed(2)}%`
                )}
                round={false}
              />
            </ul>
            <hr className="border-border-alt my-6" />
            <a
              href={`https://etherscan.io/address/${STAKING_ADDRESS}`}
              target="_blank"
              rel="noopener noreferrer"
              className="mb-2 w-full flex justify-center gap-2 align-middle items-center px-4 py-2  text-sm font-medium rounded-md text-white box-border bg-button-background-blue  border-2 border-button-border-blue hover:bg-button-hover-blue"
            >
              <Image
                src={EtherscanIcon}
                height={"16px"}
                width={"16px"}
                alt={""}
              />
              <span className="text-sm">View Contract on Etherscan</span>
            </a>
          </div>
          <div className="w-full h-full">
            <UserPanel />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Staking;
