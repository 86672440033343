import Head from "next/head";
import React, { ReactNode } from "react";
import { Header } from "./Header";
import { ConnectButton } from "@rainbow-me/rainbowkit";

interface IProps {
  children: ReactNode;
  title?: string;
}

const Layout: React.FunctionComponent<IProps> = ({
  children,
  title = "Sweet | Staking",
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico?v=10" />
        <meta property="og:url" content={"stake.sweet.gg"} key="ogurl" />
        <meta property="og:image" content={""} key="ogimage" />
        <meta property="og:site_name" content={title} key="ogsitename" />
        <meta property="og:title" content={title} key="ogtitle" />
        <meta
          property="og:description"
          content={
            "Staking Platform for Sweet - the Sweetest Sensation of GambleFi"
          }
          key="ogdesc"
        />
        <meta name="twitter:card" content="summary" key="twcard" />
        <meta name="twitter:creator" content={"sweetdotgg"} key="twhandle" />
      </Head>
      <div className="w-screen min-h-screen flex flex-col justify-start items-center overflow-y-auto overflow-x-hidden ">
        <Header />
        <div className="md:absolute md:top-14 md:right-8 mb-6 md:mb-0">
          <ConnectButton showBalance={false} />
        </div>
        <main className="h-full w-full flex flex-col justify-center items-center my-2 mx-4 md:mx-2">
          {children}
        </main>
      </div>
    </>
  );
};

export default Layout;
